<template>
  <div>
    <b-card>
      <div class="d-flex  flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between">
        <b-skeleton v-if="mainDetailsLoading" width="50%" />
        <h4 class="m-0 mr-50" v-else>
          บัญชีผู้ใช้ :
          <span>
            {{ mainDetails.phone_number }}
          </span>
        </h4>
        <b-badge
          v-if="!mainDetailsLoading"
          v-b-tooltip.hover
          pill
          :variant="mainDetails.is_ban ? 'danger' : 'success'"
          style="font-size: 0.9rem"
          class="mr-sm-50 my-50 my-sm-0"
        >
          {{ mainDetails.is_ban ? 'แบนบัญชี' : 'สามารถใช้งานได้' }}
        </b-badge>
      </div>
      <b-skeleton v-if="mainDetailsLoading" width="50%" />
      <small v-else class="text-muted">วันที่ลงทะเบียนเข้าใช้งาน {{ gFormatDate(mainDetails.created_at) }}</small>
      <div class="mt-1">
        <b-badge class="mr-75" variant="success">
          <feather-icon icon="TrendingUpIcon" size="21" />
        </b-badge>
        <span class="font-weight-bold">
          ค่าคอมมิชชั่นที่ได้รับทั้งหมด
          {{ gFormatNumberToCurrency(mainDetails.total_revenue || 0) }} บาท
        </span>
      </div>
      <div v-if="!mainDetailsLoading" class="mt-1">
        <b-button
          :variant="mainDetails.is_ban ? 'success' : 'danger'"
          size="sm"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="banOrUnBanUserAf"
        >
          {{ mainDetails.is_ban ? 'ปลดแบนบัญชี' : 'แบนบัญชี' }}
        </b-button>
      </div>
    </b-card>

    <b-row>
      <b-col cols="12" md="6">
        <statistic-card-horizontal
          icon="CreditCardIcon"
          :statistic="gFormatNumberToCurrency(mainDetails.credit_balance || 0)"
          statistic-title="เงินที่ถอนได้"
          :loading="mainDetailsLoading"
          color="success"
        />
      </b-col>
      <b-col cols="12" md="6">
        <statistic-card-horizontal
          icon="CreditCardIcon"
          :statistic="gFormatNumberToCurrency(mainDetails.hold_coin || 0)"
          statistic-title="รอโอนออก"
          :loading="mainDetailsLoading"
          color="danger"
        />
      </b-col>
    </b-row>

    <b-row class="mb-2" cols="1" cols-sm="2">
      <b-col align-self="center">
        <h3 class="mb-2 mb-md-0">สรุปข้อมูลประจำเดือน</h3>
      </b-col>
      <b-col>
        <div class="d-flex flex-column flex-sm-row justify-content-end align-items-end">
          <p class="m-0 mb-50">เลือกเดือน-ปี</p>
          <b-card body-class="p-0" class="m-0 ml-sm-1 w-100" style="max-width: 150px;">
            <v-select
              v-model="cDate"
              :options="dateSelectOptions"
              :clearable="false"
              :disabled="pageLoading || subDetailsLoading"
              :get-option-label="option => $date(option).format('MM-YYYY')"
            />
          </b-card>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="6">
        <statistic-card-horizontal
          icon="TrendingUpIcon"
          :statistic="gFormatNumberToCurrency(subDetails.saleCount)"
          statistic-title="จำนวนที่ขายได้"
          :loading="subDetailsLoading"
          color="success"
        />
      </b-col>
      <b-col cols="12" md="6">
        <statistic-card-horizontal
          icon="CreditCardIcon"
          :statistic="gFormatNumberToCurrency(subDetails.revenueTotal)"
          statistic-title="ค่าคอมมิชชั่นที่ได้รับ"
          :loading="subDetailsLoading"
          color="warning"
        />
      </b-col>
      <b-col cols="12">
        <h3>5 อันดับวอลเปเปอร์ขายดีสูงสุด</h3>
        <br />

        <b-row>
          <b-col v-for="(topRank, idx) in subDetails.topRank" :key="idx" cols="12" md="6">
            <b-card class="relative">
              <b-badge variant="warning" pill class="badge-round badge-rop-rank-item">
                <feather-icon icon="AwardIcon" /> {{ idx + 1 }}
              </b-badge>
              <div class="d-flex flex-row">
                <b-img
                  :src="topRank.topic_image_list_data_url"
                  height="auto"
                  width="100"
                  class="mr-2 mr-md-3 ct-cursor-zoom"
                  style="border-radius: 1rem"
                  @click="gZoomImage"
                />
                <div style="flex: 1">
                  <h6 class="mb-0">
                    {{ topRank.topic_image_name }}
                  </h6>
                  <small class="text-muted">{{ topRank.topic_image_list_data_title }}</small>
                  <p class="m-0 mt-50">ยอดขายทั้งหมด {{ gFormatNumberToCurrency(topRank.count_sell) }} รูป</p>
                  <p class="m-0">ค่าคอมมิชชั่นที่ได้รับ</p>
                  <h2 class="mt-1 text-success text-center">{{ gFormatNumberToCurrency(topRank.revenue) }} บาท</h2>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import vSelect from 'vue-select'

export default {
  components: {
    StatisticCardHorizontal,
    vSelect,
  },
  props: {
    userId: {
      type: String,
      default: '',
    },
    dateSelectOptions: {
      type: Array,
      default: () => [],
    },
    dateSelected: {
      type: String,
      default: null,
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subDetails: {
        clickCount: 0,
        revenueTotal: 0,
        saleCount: 0,
        topRank: [],
      },
      subDetailsLoading: false,
      mainDetails: {},
      mainDetailsLoading: false,
    }
  },
  computed: {
    cDate: {
      get() {
        return this.dateSelected
      },
      set(val) {
        this.$emit('changeDateSelect', val)
      },
    },
  },
  watch: {
    dateSelected(newVal) {
      this.fetchSubDetailsUserAfByDate(newVal, this.userId)
    },
  },
  async mounted() {
    this.fetchMainDetailsUserAf(this.userId)
    this.fetchSubDetailsUserAfByDate(this.dateSelected, this.userId)
  },
  methods: {
    async fetchMainDetailsUserAf(afId) {
      if (!afId) return
      this.mainDetailsLoading = true
      const resp = await this.api.getV2(`api/admin/affiliate/af-info/${afId}`).catch(() => null)

      if (resp && resp.code === 200) {
        this.mainDetails = { ...resp.data }
      } else {
        this.gDisplayToast('ไม่พบข้อมูลผู้ใช้ AF คนนี้', '', 'danger')
        this.$router.replace({ name: 'affiliate-user-management' })
      }

      this.mainDetailsLoading = false
    },
    async fetchSubDetailsUserAfByDate(date, afId) {
      if (!date || !afId) return
      this.subDetailsLoading = true
      const resp = await this.api
        .postV2('api/admin/affiliate/dashboard/query-data', {
          date,
          af_id: afId,
        })
        .catch(() => null)
      this.subDetailsLoading = false
      if (resp && resp.code === 200) {
        this.subDetails = { ...resp.data }
      }
    },
    async banOrUnBanUserAf() {
      const isConfirm = await this.gCheckConfirmV1(this.mainDetails.is_ban ? 'ยืนยันปลดแบนบัญชี' : 'ยืนยันแบนบัญชี')

      if (!isConfirm) return

      const urlForBanOrUnBan = this.mainDetails.is_ban ? 'api/admin/banned/unban' : 'api/admin/banned/ban'

      this.gOpenPageLoading()
      const resp = await this.api.postV2(`${urlForBanOrUnBan}/${this.userId}`, {}, this).catch(() => null)
      this.gClosePageLoading()

      if (resp && resp.code === 200) {
        this.gDisplayToast('ดำเนินการสำเร็จแล้ว')
      } else {
        this.gDisplayToast('ดำเนินการไม่สำเร็จแล้ว', resp?.data?.message, 'danger')
      }

      this.fetchMainDetailsUserAf(this.userId)
    },
  },
}
</script>

<style lang="scss" scoped>
.badge-rop-rank-item {
  position: absolute;
  top: -5px;
  right: -5px;
}
</style>
